@import '../../../styles/customMediaQueries.css';

.root {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: auto;
}

.content {
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  padding: 24px;
  background-color: var(--colorWhite);
  box-shadow: var(--boxShadowTop);
}

.avatar {
  /* Layout */
  flex-shrink: 0;
  margin: var(--TopbarMobileMenu_topMargin) 0 0 0;
}

.greeting {
  /* Font */
  composes: h1 from global;
  margin-bottom: 1px;
  margin-top: 16px;

  & span {
    @media (max-width:1024px) {
      font-weight: var(--fontWeightBold);
      font-size: 26px;
      line-height: 24px;
    }
  }
}

.logoutButton {
  /* Position and dimensions */
  display: inline;

  /* Borders */
  border: none;

  /* Text size should be inherited */
  text-decoration: none;

  /* Logout font is smaller and gray since the action is not recommended. */
  font-weight: var(--fontWeightSemiBold);
  font-size: 18px;
  line-height: 24px;

  color: var(--colorGrey300);

  /* Position component */
  width: initial;
  margin: 4px 0 24px 0;
  padding: 0;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (--viewportMedium) {
    /* margin-top + n * line-height + margin-bottom => x * 8px */
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

.inbox {
  /* Font */
  /* composes: h2 from global; */
  color: var(--marketplaceColor);
  position: relative;
  font-weight: var(--fontWeightBold);
  margin-top: auto;
  margin-bottom: 13px;

  &>span {

    &:first-child {
      @media(max-width:1024px) {
        font-size: 21px;
      }
    }
  }

  & notificationBadge {
    font-size: 16px !important;
  }
}

.navigationLink {
  /* Font */
  /* composes: h2 from global; */
  font-weight: var(--fontWeightBold);
  color: var(--marketplaceColor);
  /* Layout */
  margin-top: 0;
  margin-bottom: 11px;

  & span {
    @media(max-width:1024px) {
      font-size: 21px;
    }
  }
}

.currentPageLink {
  color: var(--marketplaceColorDark);
}

.notificationBadge {
  position: absolute;
  top: 4px;
  right: -26px;

  /* This is inside a link - remove underline */
  &:hover {
    text-decoration: none;
  }
}

.createNewListingLink {
  composes: buttonPrimary from global;
}

.authenticationGreeting {
  /* Font */
  composes: h1 from global;

  margin-bottom: 24px;
  margin-top: var(--TopbarMobileMenu_topMargin);
}

.authenticationLinks {
  white-space: nowrap;
}

.signupLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.loginLink {
  text-decoration: none;
  white-space: nowrap;
  color: var(--marketplaceColor);
}

.currentPage {
  color: var(--colorBlack);

  /* black left border */
  border-left: 5px solid black;
  margin-left: -24px;
  padding-left: 19px;
}

.divider {
  width: 60%;
  height: 1px;
  background-color: #f2b081;
}

.menuHeading {
  font-weight: var(--fontWeightBlack);
  font-size: 24px;
  line-height: 24px;
  padding-top: 16px;
  color: #818181;
}

.dropdownContent {
  @media (max-width:768px) {
    padding-bottom: 100px !important;
  }

  & li {
    padding: 6px 0px;
    color: #ff6900;
    width: 100%;
  }

  & a {
    color: #ff6900;
    font-weight: var(--fontWeightBold);
    font-size: 21px;
    line-height: 22px;
    text-decoration: none;

  }
}