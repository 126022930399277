@import '../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }

  @media(max-width: 1023px) {
    display: none;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}

.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  /* flex-grow: 1; */
  height: 100%;
  margin-right: 24px;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
  padding: 15px 0 15px 50px;

  @media (--viewportLarge) {
    /* padding-left: 0; */
    /* padding: 15px 50px; */
  }
}

.leftSideBar {
  display: flex;
  align-items: center;
}

.rightSideBar {
  display: flex;
  align-items: center;
}

.menuBox {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  & .menuDropdown {
    margin-left: 5px;
    display: inline-block;
    padding: 27px 0;
    color: #525961;
    font-size: 14px;
    line-height: 1em;
    white-space: nowrap;
  }

  & .dropdownContent {
    left: 0;
    top: 65px;
    margin: 0;
    width: 205px;
    border-radius: 3px;
    position: absolute;
    background-color: #fff;
    border: 1px solid rgba(169, 172, 176, 0.5);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);

    &::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 7%;
      margin-left: -5px;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #c1c1c178 transparent;
    }

    & .editLinkDesktop {

      &:hover {
        text-decoration: none;
      }
    }

    & li {
      padding: 15px 20px;
      border-bottom: 1px solid rgba(169, 172, 176, 0.5);
      color: #525961;
      width: 100%;
      letter-spacing: 0.09px;
      font-size: 16px;
      font-weight: 400;
      position: relative;

      &:hover {
        background-color: rgba(169, 172, 176, 0.07);

        &::after {
          content: "";
          width: 6px;
          height: 55px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #ff6900;
        }
      }

      & a {
        color: #525961;
        width: 100%;
        letter-spacing: 0.09px;
        font-size: 16px;
        font-weight: 400;
        text-decoration: none;

        &:hover {
          color: #000;
        }
      }
    }

    &:last-child {
      border-bottom: none;
    }
  }
}


.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* Create listing (CTA for providers) */
.createListingLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--marketplaceColor);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--marketplaceColorDark);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.topbarDesktopLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

.createListing {
  composes: topbarDesktopLabel;
}

/* Inbox */
.inboxLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  height: 100%;
  padding: 0 12px 0 12px;
  margin: 0;

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: 0;
  }
}

.inbox {
  composes: topbarDesktopLabel;
  position: relative;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 2px;
  right: -9px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* Authentication */
.signupLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.loginLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  flex-shrink: 0;
  height: 100%;
  padding: 0 12px 0 12px;

  color: var(--colorGrey700);

  &:hover {
    color: var(--colorBlack);
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }
}

.signup,
.login {
  composes: textSmall from global;

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.profileSettingsLink,
.yourListingsLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}
